<template>
  <div>
    <vs-button
      v-bind:class="{ active: $query.tab === 'all' }"
      color="primary"
      :type="$query.tab === 'all' ? 'filled' : 'line'"
      @click="handleTabClick('all')"
      class="tab-btn"
    >
      Tüm Kullanıcılar
      <span class="font-medium" v-if="total && $query.tab === 'all'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'unapproved' }"
      color="warning"
      :type="$query.tab === 'unapproved' ? 'filled' : 'line'"
      @click="handleTabClick('unapproved')"
      class="tab-btn"
    >
      Onaylanmayanlar
      <span class="font-medium" v-if="total && $query.tab === 'unapproved'">({{ total }})</span>
    </vs-button>

    <vs-button
      v-bind:class="{ active: $query.tab === 'banned' }"
      color="danger"
      :type="$query.tab === 'banned' ? 'filled' : 'line'"
      @click="handleTabClick('banned')"
      class="tab-btn"
    >
      Banlananlar
      <span class="font-medium" v-if="total && $query.tab === 'banned'">({{ total }})</span>
    </vs-button>
    <user-list :list="list" @search="handleSearch" @action="handleActions" />
    <div class="con-pagination-table vs-table--pagination">
      <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import UserList from '@/components/user/UserList.vue';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  components: { UserList },
  mixins: [usePagination({ moduleName: 'user' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('user', ['list', 'total'])
  },
  methods: {
    ...mapActions('user', ['getUsers', 'verifyUser', 'unbanUser', 'deleteUser']),
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.search;
      delete this.$query.tab;
      delete this.$query.id;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('search')) return;
      if (this.$query.hasOwnProperty('id')) return;
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: 'all' } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },

    filterList(type) {
      this.$vs.loading();
      this.getUsers(type, this.fetchParams).finally(() => {
        this.$vs.loading.close();
      });
    },

    handleTabClick(type) {
      this.changeRoute({ tab: type }, { reset: true });
    },
    handleSearch({ text }) {
      const oldVal = this.$query.search;
      if (text === oldVal) {
        return;
      }
      if (text.length > 0 && text.length < 3) {
        this.$vs.notify({
          text: 'Minumum 3 karakter girmelisiniz.',
          color: 'warning'
        });
      }
      if (text.length === 0) {
        this.changeRoute({ page: 1, tab: 'all' }, { reset: true });
      }
      if (text.length >= 3) {
        this.changeRoute({ search: text }, { reset: true });
      }
    },

    handleAction({ text, successText, action, id }) {
      if (confirm(text)) {
        this.$vs.loading();
        this[action](id)
          .then(() => {
            this.$vs.notify({
              color: 'success',
              text: successText
            });
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    handleUnbanUser({ id, action }) {
      const text = 'Kullanıcının banını kaldırmak ıstediğinize emin misiniz?';
      const successText = 'Kullanıcının banı kaldırıldı.';
      this.handleAction({ text, successText, id, action });
    },

    handleDeleteUser({ id, action }) {
      const text = 'Kullanıcıyi silmek ıstediğinize emin misiniz?';
      const successText = 'Kullanıcı silindi.';
      this.handleAction({ text, successText, id, action });
    },

    handleVerifyUser({ id, action }) {
      const text = 'Kullanıcıyı onaylamak istediğinize emin misiniz?';
      const successText = 'Kullanici onaylandı.';
      this.handleAction({ text, successText, id, action });
    },

    handleActions({ action, payload }) {
      const handleName = 'handle' + _.upperFirst(action);
      this[handleName]({ ...payload, action });
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      await this.filterList(this.$query.tab);
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style>
.tab-btn + .tab-btn {
  margin: 5px;
}
</style>
