<template>
  <vs-popup title="Banla" :active.sync="active" @close="close" class="popup--ban-user">
    <div>
      <label for="cause">Sebep</label>
      <vs-textarea name="cause" counter="140" :counter-danger.sync="counterDanger" v-model="cause" />
    </div>
    <div class="centerx labelx">
      <label for="cause">Süre</label>
      <v-select :options="durations" v-model="duration" placeholder="Seçiniz..."></v-select>
    </div>
    <div class="action-buttons text-right mt-6">
      <vs-button type="flat" @click="close">
        Vazgeç
      </vs-button>
      <vs-button class="ml-4" @click="submit" :disabled="!duration">
        Güncelle
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
export default {
  name: 'PopupBanUser',
  components: {
    'v-select': vSelect
  },
  props: ['isActive', 'user'],
  data() {
    return {
      active: false,
      counterDanger: false,
      cause: '',
      durations: [
        { hour: 24, label: '1 Gün' },
        { hour: 72, label: '3 Gün' },
        { hour: 168, label: '1 Hafta' },
        { hour: 720, label: '1 ay' },
        { hour: 8760, label: '1 yıl' },
        { hour: 876000, label: 'Süresiz' }
      ],
      duration: null
    };
  },
  computed: {},
  methods: {
    ...mapActions('user', ['banUser']),
    close() {
      this.$emit('close', false);
    },
    async submit() {
      if (confirm('Kullanıcıyı banlamak istediğinize emin misiniz?')) {
        try {
          let payload = {
            userId: this.user.onedio_user_id,
            duration: this.duration.hour,
            cause: this.cause
          };
          await this.banUser(payload);
          this.$vs.notify({
            color: 'success',
            text: 'Kullanıcı başarıyla banlandı.'
          });
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'Bir hata oluştu.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    }
  }
};
</script>
<style lang="scss">
.popup--ban-user {
  .vs-popup {
    max-width: 450px;
  }
  .vs-popup--content {
    overflow: visible;
  }
}
</style>
