<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" @sort="handleSort" noDataText="">
      <div class="flex items-center justify-between w-full" slot="header">
        <div v-if="$store.state.route.query.search">
          <h3>aranan: {{ $store.state.route.query.search }}</h3>
        </div>
        <div class="con-input-search vs-table--search">
          <input v-model="search" maxlength="50" v-on:keyup.enter="handleSearch" class="input-search vs-table--search-input" type="text" />
          <vs-icon icon="search"></vs-icon>
        </div>
      </div>
      <template slot="thead">
        <vs-th sort-key="order_status">Durum</vs-th>
        <vs-th sort-key="name">İsim</vs-th>
        <vs-th sort-key="updateDate">Kullanıcı Adı</vs-th>
        <vs-th sort-key="createDate">Email</vs-th>
        <vs-th sort-key="updateDate">Güncellenme Tarihi</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="status">
              <div class="flex flex-wrap w-16">
                <div class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip :text="states[tr.state].text" class="state-tooltip">
                    <div
                      class="w-5 h-5 text-sm rounded-full text-white font-bold flex items-center justify-center leading-none"
                      :class="states[tr.state].color"
                    >
                      {{ states[tr.state].shortText }}
                    </div>
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>

            <vs-td id="user" :data="tr.name" class="break-word min-w-48">
              <div>
                <strong id="entrytopic">
                  {{ tr.name ? tr.name : '-' }}
                </strong>
              </div>
            </vs-td>
            <vs-td :data="tr.username" class="break-all min-w-48">
              {{ tr.username }}
            </vs-td>
            <vs-td :data="tr.email" class="break-all min-w-48">
              {{ tr.email }}
            </vs-td>

            <vs-td id="updateDate">
              <p class="w-30 font-medium">
                {{ tr.updated_at | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updated_at | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td>
              <div class="flex items-center space-x-2 justify-end">
                <div class="flex space-x-1">
                  <vx-tooltip text="Banla" v-if="!tr.ban_status">
                    <vs-button
                      size="small"
                      class="btn btn--action"
                      @click="handleBanUser($event, tr.onedio_user_id)"
                      color="warning"
                      icon="report"
                      type="gradient"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip text="Banı Kaldır" v-if="tr.ban_status">
                    <vs-button
                      size="small"
                      class="btn btn--action"
                      @click="handleUnbanUser($event, tr.onedio_user_id)"
                      color="warning"
                      icon="report_off"
                      type="gradient"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip text="Onayla" v-if="!tr.is_admin_verified">
                    <vs-button
                      size="small"
                      class="btn btn--action"
                      @click="handleVerifyUser($event, tr.onedio_user_id)"
                      color="success"
                      icon="check_circle"
                      type="gradient"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip text="Sil" v-if="!tr.deleted">
                    <vs-button
                      size="small"
                      class="btn btn--action"
                      @click="handleDeleteUser($event, tr.onedio_user_id)"
                      color="danger"
                      icon="delete"
                      type="gradient"
                    ></vs-button>
                  </vx-tooltip>
                </div>
                <vs-dropdown
                  vs-trigger-click
                  class="action-button action-button--topic cursor-pointer rounded border border-solid border-gray-300 bg-gray-100 hover:bg-gray-300 px-1"
                >
                  <div class="flex items-center">
                    <feather-icon icon="MoreHorizontalIcon" svgClasses="more-icon" />
                  </div>

                  <vs-dropdown-menu class="w-56">
                    <vs-dropdown-item @click="showSendMessageDialog = true">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-send" icon-pack="feather" />
                        </div>
                        <span>Mesaj Gönder</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <popup-ban-user :is-active="showBanDialog" @close="showBanDialog = false" :user="selected"></popup-ban-user>
    <popup-send-message :user="selected" :is-active="showSendMessageDialog" @close="showSendMessageDialog = false" />
  </div>
</template>

<script>
import PopupSendMessage from '@/components/popups/PopupSendMessage.vue';
import PopupBanUser from '@/components/user/PopupBanUser.vue';
import { generateImageUrl } from '@/utils/imageHash';
export default {
  props: ['list'],
  components: { PopupBanUser, PopupSendMessage },
  data: () => ({
    search: '',
    selected: [],
    showBanDialog: false,
    showUpdateDialog: false,
    showSendMessageDialog: false,
    states: {
      deleted: {
        color: 'bg-red-500',
        shortText: 'S',
        text: 'Silinmiş'
      },
      waiting: {
        color: 'bg-orange-500',
        shortText: 'O',
        text: 'Onaylanmamış'
      },
      approved: {
        color: 'bg-green-500',
        shortText: 'O',
        text: 'Onaylanmış'
      },
      banned: {
        color: 'bg-red-500',
        shortText: 'B',
        text: 'Banlanmış'
      },
      restored: {
        color: 'bg-gray-500',
        shortText: 'GG',
        text: 'Geri Getirilmiş'
      }
    }
  }),
  created() {
    this.search = this.$store.state.route.query.search || '';
  },
  watch: {
    search(val) {
      const query = this.$store.state.route.query;
      if (val.length === 0 && query.hasOwnProperty('search')) {
        delete query.search;
        this.$router.replace({ query: { ...query, page: 1, tab: 'all' } });
      }
    }
  },
  methods: {
    handleBanUser() {
      this.showBanDialog = true;
    },

    handleUnbanUser($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'unbanUser', payload });
    },

    handleDeleteUser($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'deleteUser', payload });
    },

    handleVerifyUser($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'verifyUser', payload });
    },

    handleSearch() {
      this.$emit('search', { text: this.search });
    },

    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        type: 'png',
        quality: '80',
        imageUrl: image
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },

    handleSort(key, active) {
      console.log(`ordered: ${key} ${active}\n`);
    }
  }
};
</script>
