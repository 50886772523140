var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "popup--ban-user",
      attrs: { title: "Banla", active: _vm.active },
      on: {
        "update:active": function($event) {
          _vm.active = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "div",
        [
          _c("label", { attrs: { for: "cause" } }, [_vm._v("Sebep")]),
          _c("vs-textarea", {
            attrs: {
              name: "cause",
              counter: "140",
              "counter-danger": _vm.counterDanger
            },
            on: {
              "update:counterDanger": function($event) {
                _vm.counterDanger = $event
              },
              "update:counter-danger": function($event) {
                _vm.counterDanger = $event
              }
            },
            model: {
              value: _vm.cause,
              callback: function($$v) {
                _vm.cause = $$v
              },
              expression: "cause"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "centerx labelx" },
        [
          _c("label", { attrs: { for: "cause" } }, [_vm._v("Süre")]),
          _c("v-select", {
            attrs: { options: _vm.durations, placeholder: "Seçiniz..." },
            model: {
              value: _vm.duration,
              callback: function($$v) {
                _vm.duration = $$v
              },
              expression: "duration"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "action-buttons text-right mt-6" },
        [
          _c(
            "vs-button",
            { attrs: { type: "flat" }, on: { click: _vm.close } },
            [_vm._v("\n      Vazgeç\n    ")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { disabled: !_vm.duration },
              on: { click: _vm.submit }
            },
            [_vm._v("\n      Güncelle\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }